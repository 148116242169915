import React from 'react';

import { colors } from '@mui/material';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import PageviewIcon from '@mui/icons-material/Pageview';
import BuildIcon from '@mui/icons-material/Build';
import StoreIcon from '@mui/icons-material/Store';
import ListIcon from '@mui/icons-material/List';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';

import FaceIcon from '@mui/icons-material/Face';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmailIcon from '@mui/icons-material/Email';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import DesktopAccessDisabledRoundedIcon from '@mui/icons-material/DesktopAccessDisabledRounded';
import NoEncryptionRoundedIcon from '@mui/icons-material/NoEncryptionRounded';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';

export { SubmenubarList };
const SubmenubarList = (da, theme) => {

  const nav = da.getNav();

  const isAccountOwner = da.isAccountOwner();      // members, invites,     , users,
  //const isAccountOwnerSubscriber = da.isAccountOwnerSubscriber();
  const isAccountOwnerSubscriber = true;

  const buttonList = [
    {
      code: 'home',
      display: 'Home',
      bucket: {
	iconLeft: <HomeIcon />,
	tip: 'App home page',
      },
    },
  ];

  //if (da.isDev())
  //if (isAccountOwner)

  buttonList.push({
    code: 'account',
    display: 'Account',
    bucket: {
      iconLeft: <AccountBoxIcon />,
      tip: 'Manage account',
    },
  });

  buttonList.push({
    code: 'subscriptions',
    display: 'Subscriptions',
    bucket: {
      iconLeft: <AddShoppingCartIcon />,
      tip: 'Subscription Plans',
    },
  });

  buttonList.push({
    code: 'payments',
    display: 'Payments',
    bucket: {
      iconLeft: <CurrencyExchangeIcon />,
      tip: 'Recurring Payments',
    },
  });

  if (da.isDev())
    buttonList.push({
      code: 'join',
      display: 'Join Team',
      bucket: {
        iconLeft: <AccountCircleIcon />,
        tip: 'Register a request to join a team',
      },
    });

  if (isAccountOwnerSubscriber)
    buttonList.push({
      code: 'members',
      display: 'Members',
      bucket: {
        iconLeft: <SupervisedUserCircleIcon />,
        tip: 'Manage member invitations',
      },
    });


  if (da.isDev())
    if (isAccountOwner)
      buttonList.push({
        code: 'users',
        display: 'Users',
        bucket: {
          iconLeft: <GroupIcon />,
          tip: 'Manage users',
        },
      });

/*
  if (da.isDev())
    if (isAccountOwner)
      buttonList.push({
        code: 'permissions',
        display: 'Permissions',
        bucket: {
          iconLeft: <DesktopAccessDisabledRoundedIcon />,
          tip: 'Manage permissions',
        },
      });

  if (da.isDev())
    if (isAccountOwner)
      buttonList.push({
        code: 'preferences',
        display: 'Preferences',
        bucket: {
          iconLeft: <FavoriteIcon />,
          tip: 'Manage preferences',
        },
      });
*/
  
  return buttonList;
}

/*
   buttonList.push({
   code: 'signin',
   display: 'Sign In',
   bucket: {
   type: 'button',
   iconLeft: <LockRoundedIcon />,
   iconRight: null,
   className: nav === 'signin' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: 'default',
   tip: 'User authentication',
   },
   });

   buttonList.push({
   code: 'signup',
   display: 'Sign Up',
   bucket: {
   type: 'button',
   iconLeft: <PersonAddIcon />,
   iconRight: null,
   className: nav === 'signup' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: '#ff0000',
   tip: 'User registration',
   },
   });

   buttonList.push({
   code: 'forgot',
   display: 'Forgot Password',
   bucket: {
   type: 'button',
   iconLeft: <NoEncryptionRoundedIcon />,
   iconRight: null,
   className: nav === 'forgot' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: 'default',
   tip: 'Retrieve forgotten password',
   },
   });

   buttonList.push({
   code: 'changeid',
   display: 'Change Email',
   bucket: {
   type: 'button',
   iconLeft: <EmailIcon />,
   iconRight: null,
   className: nav === 'changeid' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: 'default',
   tip: 'Change registered email address',
   },
   });

   buttonList.push({
   code: 'changepwd',
   display: 'Change Password',
   bucket: {
   type: 'button',
   iconLeft: <LockRoundedIcon />,
   iconRight: null,
   className: nav === 'changepwd' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: 'default',
   tip: 'Change access password',
   },
   });

   buttonList.push({
   code: 'reverify',
   display: 'Reverify',
   bucket: {
   type: 'button',
   iconLeft: <SettingsBackupRestoreRoundedIcon />,
   iconRight: null,
   className: nav === 'changepwd' ? classes.activeButton : classes.navButton,
   variant: 'contained',
   color: 'default',
   tip: 'Request an email to verify signup',
   },
   });

 */


