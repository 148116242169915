import { genMuiThemes } from 'clnt-common';

const ltp    = '#65621c';   //0f1bc7';    // light color with red tint - nav bar/footer/submenubar bg color
const lts    = '#EF5350';    // button bg in light mode (darker purple) - secondary / also used in the manifest.webmanifest
const ltpnl  = '#ffffff';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#a9ab6f';    // light content panel -  (The same is used in manifest.webmanifest)

const dkp    = '#65621c';   //0f1bc7';    //
const dks    = '#EF5350';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#323232';    // compliment to ltpnl
const dkcpnl = '#323232';    // dark content panel

const options = {
  fontHeader :      'Albra',
  fontText :        'Inter',

  primaryLight:     ltp,
  secondaryLight:   lts,
  panelBgLight:     ltpnl,
  backpanelBgLight: ltp,
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp,
  secondaryDark:    dks,
  panelBgDark:      dkpnl,
  backpanelBgDark:  dkp,
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#af9d9d', border: '#cccffa' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }],  

  borderLight : '#ff0000',      // Used for FORM container border
  borderDark : '#ffff00',       // Used for FORM container border
};

export { themes };
let themes = genMuiThemes(options);



